<template lang="pug">
  #auth-wrapper
    LanguageSelect.language-select
    Body
    Footer
</template>

<script>
  export default {
    components: {
      Body: () => import("@/components/layout/Body"),
      LanguageSelect: () => import("@/components/LanguageSelect"),
      Footer: () => import("@/components/layout/Footer")
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  #auth-wrapper
    min-height: 100vh
    position: relative
    background: linear-gradient(135deg, #224477 0%, #0e9ea5 100%)

    #body
      padding-bottom: $footer-height + 30px
      width: 100%

    .language-select
      z-index: 10
      position: absolute
      top: 30px
      right: 30px
</style>
